import {useEffect, useMemo} from 'react'
import {useSelector} from "react-redux";
import {find, sortBy} from "lodash/collection";
import {getQueryLinkParams} from "../../helpers/routing-helper";

const useLotListFn = () => {

    const {
        lotsData, phrases, all_categories, xenOptions,
        user_sort_data, visitor, time_wait_pay, time_wait_take, time_wait_send, user_id
    } = useSelector(state => state.lots);
    const changeLots = useSelector(state => state.modal.changeLots);

    const {type, user_lots_type, year} = getQueryLinkParams();



    const auction_categories = useMemo(() => {
        return sortBy(all_categories, ['parent_category_id', 'display_order']);
    }, [all_categories]);


    const show_img_lots_list = useMemo(() => {
        const obj = find(user_sort_data, {user_lots_type, type});
        return obj.show_img_lots_list
    }, [user_lots_type, type])

    return {
        promotion_color: xenOptions.promotion_color,
        show_img_lots_list,
        auction_categories,
        user_lots_type,
        time_wait_take,
        time_wait_send,
        time_wait_pay,
        changeLots,
        lotsData,
        phrases,
        visitor,
        user_id,
        type,
        year,
    }
}
export default useLotListFn