import React, {createContext, useEffect, useMemo, useRef, useState, useTransition} from "react"
import './auction-lots-listing.css';
import MainLotView from "./components/main-lots-view";

import useLotListFn from "./useLotListFn";
import LotsListItem from "./components/lots-list-item/lots-list-item";
import StartSpinerBlock from "../start-spiner-block";

const LotsContext = createContext();
export {LotsContext};
const AuctionLotsListing = () => {
    const lotListData = useLotListFn();
    const {lotsData = [], phrases: {you_have_no_auctions, no_lots_sold_or_hidden}, type} = lotListData

    const [isPending, startTransition] = useTransition();
    const [renderedLots, setRenderedLots] = useState([]);
    const containerRef = useRef(null);

    useEffect(() => {
        startTransition(() => {
            setRenderedLots(lotsData);
        });
    }, [lotsData]);

    const lotList = useMemo(() => {
        if (renderedLots.length === 0) {
            <div key={'no_lots'}>{type === 'sale' ? no_lots_sold_or_hidden : you_have_no_auctions}</div>
        }
        return renderedLots.map((lotData) => <LotsListItem
            key={type === 'all_user_bets' ? lotData.bid_id : lotData.auction_id}
            lotData={lotData}
        />)
    }, [renderedLots]);

    useEffect(() => {
        if (containerRef.current && window.XenForo && typeof window.XenForo.activate === 'function') {
            window.XenForo.activate(document)
        }
    }, [lotList]);

    return (
        <MainLotView ref={containerRef}>
            <LotsContext.Provider value={{
                ...lotListData
            }}>
                {isPending ? <StartSpinerBlock/> : lotList}
            </LotsContext.Provider>
        </MainLotView>
    );
};

export default AuctionLotsListing;
