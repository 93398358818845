import React from 'react';
import './page-nav.css';
import {getLinkData} from "../../redux/lots/action";
import {setParamsPageNav} from "../../redux/page-nav/actionPageNav";
import PaginationBlock from "./pagination-block";
import {useDispatch} from "react-redux";
import { useNavigate } from "react-router-dom";

const PageNav = (props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const setActivePage = (activePage) => {
        const queryLink = dispatch(getLinkData({page: activePage}));
        navigate(`?${queryLink}`);

        dispatch(setParamsPageNav({activePage}));
    };

    const setActivePageBottomNav = (activePage) => {
        setActivePage(activePage);
        window.scrollTo({top: 0, behavior: 'smooth'});
    }


    return (
        <>
            <PaginationBlock setActivePage={setActivePage}/>
            {props.children}
            <PaginationBlock setActivePage={setActivePageBottomNav}/>
        </>

    )
}
export default PageNav