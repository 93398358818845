import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {getUrl} from "../../../redux/lots/action";
import {getQueryLinkParams} from "../../../helpers/routing-helper";
import GlobalHelper from "../../../helpers/global-helper";
import find from 'lodash/find';
import {useCallback, useEffect} from "react";
import {includes} from "lodash/collection";
import {isEmpty} from "lodash/lang";

const useBreadCrumbsOtherPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {auction_ph, link, user_id, user_lots_type, type, user_owner_data, visitor, sidebar_phrases} = useSelector(state => {
        const {sidebar, lots} = state;
        const {visitor, user_owner_data} = lots;
        const {link} = dispatch(getUrl());
        const {user_id, user_lots_type, type} = getQueryLinkParams()

        return {auction_ph: lots.phrases.auctions, visitor, link, user_id, user_lots_type, type, user_owner_data, sidebar_phrases: sidebar.phrases};
    });

    useEffect(() => {
        if (!includes(['auction_lots', 'archive_lots'], user_lots_type) && !isEmpty(sidebar_phrases) && !isEmpty(user_owner_data)) {
            setBreadCrumbs()
        }
    }, [user_lots_type, sidebar_phrases, user_owner_data]);

    const setBreadCrumbs = () => {

            const dataNav = GlobalHelper.getNavigationData(sidebar_phrases);
            const {owner_title = '', title: dataTitle} = find(dataNav, {user_lots_type, type});

            const title = (visitor.user_id !== user_id && user_id !== undefined)
                ? owner_title.replace(/\{name\}/, user_owner_data.username) : dataTitle;

            document.title = `${title} | REIBERT.info`;

        let xenTitle = GlobalHelper.getBreadcrumbsTitleContainer()
            let documentTitle = title;
            let lotsBreadCrumbs = [
                {
                    'href': '/',
                    'value': auction_ph,
                    action: () => {
                        navigate('/');
                    }
                }
            ];
            lotsBreadCrumbs.push({
                'href': link,
                'value': title
            });

            if (xenTitle !== null) {
                xenTitle.innerText = documentTitle;
            }

            const breadcrumsTop = document.querySelector('.breadBoxTop nav fieldset.breadcrumb span.crumbs');
            const breadcrumsBottom = document.querySelector('.breadBoxBottom nav fieldset.breadcrumb span.crumbs');

            if (breadcrumsTop !== null && breadcrumsBottom !== null) {
                GlobalHelper.makeBreadCrumb(breadcrumsTop, lotsBreadCrumbs)
                GlobalHelper.makeBreadCrumb(breadcrumsBottom, lotsBreadCrumbs)
            }
        }




}
export default useBreadCrumbsOtherPage